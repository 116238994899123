import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyDesignOverview, StudyDesignArms, StudyCopyBlock } from '../index';

const MCC = () => (
    
    <ComponentWrapper>
                <StudyDesignOverview
            title="Overview"
            description="The efficacy of KEYTRUDA was investigated in KEYNOTE&#8288;-&#8288;017 and KEYNOTE&#8288;-&#8288;913, two multicenter, nonrandomized,
            open-label trials that enrolled 105 patients with recurrent locally advanced or metastatic MCC who had not received prior systemic therapy for their advanced disease. KEYNOTE&#8288;-&#8288;017 was a phase 2 trial and KEYNOTE&#8288;-&#8288;913 was a confirmatory phase 3 trial.<sup>1,2<sup/>"
            data={[
                [
                    {
                        title: 'Select Eligibility Criteria<sup>1,2<sup/>',
                        description: `
                            <ul>
                                <li>KEYNOTE&#8288;-&#8288;017: Age ≥18 years; KEYNOTE&#8288;-&#8288;913: Age ≥12 years</li>
                                <li>Recurrent locally advanced or metastatic MCC</li>
                                <li>No prior systemic therapy for advanced disease</li>
                                <li>Without active autoimmune disease</li>
                                <li>No medical condition that required immunosuppression</li>
                                <li>ECOG PS 0 or 1<sup>e</sup></li>
                            </ul>
                        `,
                        type: 'box-gradient',
                        colspan: 2
                    }
                ],
                [
                    {
                        type: 'arrow-gray',
                        colspan: 2
                    }
                ],
                [
                    {
                        title: 'Randomized (N=105)',
                        colspan: 2
                    }
                ],
            ]}
        />

        <StudyDesignArms title="Treatment Interventions" arms={[
                {
                title: "KEYNOTE&#8288;-&#8288;017: (n=50)",
                    data: [
                        [
                            {
                                title: 'Treatment',
                                description: `
                                    <p>Pembrolizumab 2&nbsp;mg/kg IV Q3W</p>
                                `,
                                type: 'box-green'
                            }
                        ],
                        [
                            {
                                type: 'arrow-green'
                            }
                        ],
                        [
                            {
                                title: 'Treatment Duration',
                                description: 'Treatment continued for up to 24 months or until unacceptable toxicity or disease progression.'
                            }
                        ]
                    ]
                },
                {
                title: "KEYNOTE&#8288;-&#8288;913: (n=55)",
                    data: [
                        [
                            {
                                title: 'Treatment',
                                description: `
                                    <p>Pembrolizumab 200&nbsp;mg IV Q3W in adults or 2&nbsp;mg/kg (up to a maximum of 200&nbsp;mg) IV Q3W in pediatric patients</p>
                                `,
                                type: 'box-green'
                            }
                        ],
                        [
                            {
                                type: 'arrow-green'
                            }
                        ],
                        [
                            {
                                title: 'Treatment Duration',
                                description: 'Treatment continued for up to 24 months or until unacceptable toxicity or disease progression.'
                            }
                        ]
                    ]
                }
        ]} 
    />

    <StudyCopyBlock 
            title="Endpoints"
            description="The major efficacy outcome measures were ORR and DOR assessed by BICR per RECIST v1.1."
    />

    
        <StudyCopyBlock 
            title="KEYNOTE&#8288;-&#8288;017 and KEYNOTE&#8288;-&#8288;913 Patient Characteristics"
            description={`
                <ul>
                    <li><strong>Median age, years (range):</strong> 73 (38–91)
                        <ul>
                            <li>≥65 years, %: 79</li>
                        </ul>
                    </li>
                <li><strong>Male, %:</strong> 62</li>
                <li><strong>Race, %</strong>
                    <ul>
                        <li>White: 80</li>
                        <li>Unknown or missing: 19</li>
                        <li>Asian: 1</li>
                    </ul>
                </li>
                <li><strong>ECOG PS, %</strong>
                    <ul>
                        <li>0: 53</li>
                        <li>1: 47</li>
                    </ul>
                </li>
                <li><strong>Disease stage, %</strong>
                    <ul>
                        <li>Stage IIIB disease: 13</li>
                        <li>Stage IV disease: 84</li>
                    </ul>
                </li>
                <li><strong>Prior therapy, %</strong>
                    <ul>
                        <li>Prior surgery: 76</li>
                        <li>Prior radiation therapy: 51</li>
                    </ul>
                </li>
            `} 

            footnotes={[
                {
                    label:'e.',
                    text: 'Lansky Play-Performance Scale (LPS) ≥50 for pediatric participants up to and including 16 years of age in KEYNOTE&#8288;-&#8288;913.<sup>2</sup>'
                }       
            ]}
            definitions="ECOG PS = Eastern Cooperative Oncology Group performance status; IV = intravenous; Q3W = every 3 weeks; BICR = blinded independent central review; RECIST v1.1 = Response Evaluation Criteria in Solid Tumors v1.1." 
        />
    
       
    </ComponentWrapper>
);

export default MCC;
