import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, SecondaryCallout, EndpointDisplay, ComparisonTable, NCCNcallout, PatientProfile, ReferencesBlock } from '../../components';
import MCC from '../../components/StudyDesign/__study-design/mcc'
import { jobCodes } from '../../util/data/jobCodes';
import { StaticImage } from 'gatsby-plugin-image';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-017 - Advanced Merkel Cell Carcinoma (MCC) - HCP`,
    keywords: `keynote 017, keynote 913`,
    description: `Health care professionals may find clinical findings from KEYNOTE-017 and KEYNOTE-913 in patients with recurrent locally advanced or metastatic Merkel cell carcinoma`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/merkel-cell-carcinoma/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/merkel-cell-carcinoma/","@type":"MedicalTrial","name":"KEYNOTE-017","description":"Clinical Findings from KEYNOTE⁠-⁠017","trialDesign":"The efficacy of KEYTRUDA was investigated in KEYNOTE⁠-⁠017 and KEYNOTE⁠-⁠913, two multicenter, nonrandomized, open-label trials that enrolled 105 patients with recurrent locally advanced or metastatic MCC who had not received prior systemic therapy for their advanced disease."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/merkel-cell-carcinoma/","@type":"MedicalTrial","name":"KEYNOTE-913","description":"Clinical Findings From KEYNOTE⁠-⁠913, a Phase 3 Clinical Trial","trialDesign":"The efficacy of KEYTRUDA was investigated in KEYNOTE⁠-⁠017 and KEYNOTE⁠-⁠913, two multicenter, nonrandomized, open-label trials that enrolled 105 patients with recurrent locally advanced or metastatic MCC who had not received prior systemic therapy for their advanced disease."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/merkel-cell-carcinoma/","@type":"MedicalEntity","guideline":"NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) recommend pembrolizumab (KEYTRUDA) as a preferred systemic therapy option for patients with disseminated (M1) MCC (category 2A).","recognizingAuthority":"NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) recommend pembrolizumab (KEYTRUDA) as a preferred systemic therapy option for patients with disseminated (M1) MCC (category 2A). Per NCCN Guidelines®, pembrolizumab (KEYTRUDA) is also preferred for patients with primary locally advanced, recurrent locally advanced, or recurrent regional MCC, if curative surgery and curative radiation therapy are not feasible (category 2A).3 All preferred systemic therapies have a category 2A recommendation for treatment of MCC."}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 14;
const jobCode = jobCodes[14].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/merkel-cell-carcinoma/' },
                { text: 'Dosing', url: '/dosing/options/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const pageReferences = [
    {
        label: "1.",
        text: "Identifier: NCT02267603. Pembrolizumab in treating patients with advanced Merkel cell carcinoma. Updated July 5, 2023. Accessed March 28, 2024. ClinicalTrials.gov. https://clinicaltrials.gov/study/NCT02267603"
    },
    {
        label: "2.",
        text: "Clinicaltrials.gov. Identifier: NCT03783078. Pembrolizumab (MK-3475) as first-line therapy for advanced Merkel cell carcinoma (MK-3475-913). Updated February 28, 2024. Accessed February 29, 2024. ClinicalTrials.gov. https://clinicaltrials.gov/study/NCT03783078"
    },
    {
        label: "3.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Merkel Cell Carcinoma V.1.2024. © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed December 20, 2023. To view the most recent and complete version of the guideline, go online to NCCN.org."
    },
]

const anchorLinkModelData = [
    { label: 'Clinical Findings From KEYNOTE&#8288;-&#8288;017, a Phase 2 Clinical Trial<sup>1<sup/>'},
    { label: 'Clinical Findings From KEYNOTE&#8288;-&#8288;913, a Phase 3 Clinical Trial<sup>2<sup/>' }, 
    { label: 'Study Designs for KEYNOTE&#8288;-&#8288;017 and KEYNOTE&#8288;-&#8288;913' }, 
    { label: 'Select NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for MCC' },
    { label: 'Hypothetical Patient Case' }
]

const NCCNcalloutData = {
    bodyCopy: `
        <p><strong>NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) recommend pembrolizumab (KEYTRUDA) as a preferred systemic therapy option for patients with disseminated (M1) MCC (category 2A).</strong></p>
        <p><strong>Per NCCN Guidelines®, pembrolizumab (KEYTRUDA) is also preferred for patients with primary locally advanced, recurrent locally advanced, or recurrent regional MCC, if curative surgery and curative radiation therapy are not feasible (category 2A).<sup>3</sup></strong></p>
        <p><strong>All preferred systemic therapies have a category 2A recommendation for treatment of MCC.</strong></p>
    `,
    showBadge: false,
}

const NCCNcalloutDefinitions = `
    <p>NCCN makes no warranties of any kind whatsoever regarding their content, use, or application and disclaims any responsibility for their application or use in any way.</p>
    <p>Category 2A = Based upon lower-level evidence, there is uniform National Comprehensive Cancer Network® (NCCN®) consensus that the intervention is appropriate.<sup>3</sup></p>
`

const NCCNcalloutReferences = [
    {
        label: '3.',
        text: 'Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Merkel Cell Carcinoma V.1.2024. © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed December 20, 2023. To view the most recent and complete version of the guideline, go online to NCCN.org.'
    }
]

const dorComparisonFootnotes = [
    {
        label: "+",
        text: "Denotes ongoing response."
    }
]

const patientProfileNathan = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/patient-nathan.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "Nathan",
    diagnosis: "Stage IV MCC",
    primaryCharacteristics: [
        {
            key: 'Past Medical and Surgical History',
            value: 'Stage I Merkel Cell carcinoma of the left forearm (diagnosed 8 months ago and treated with wide local excision with clear margins)',
            emphasize: false,
        },
        {
            key: 'Current Presentation',
            value: 'New, rapidly enlarging 2-cm cutaneous lesion on the left shoulder and two palpable axillary lymph nodes',
            emphasize: false,
        },
        {
            key: 'Current Imaging',
            value: 'PET/CT scan showed hypermetabolic activity in the left shoulder lesion and axillary lymph nodes along with hypermetabolic lesions in the lungs concerning for distant metastases',
            emphasize: false,
        },
        {
            key: 'Biopsy',
            value: 'Excisional biopsy of axillary LN positive for MCC (immunohistochemistry [IHC] confirmed)',
            emphasize: false,
        },
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '71',
        },
        {
            key: 'Gender',
            value: 'Male',
        },
        {
            key: 'ECOG PS',
            value: '0',
        },
        {
            key: 'Family History',
            value: 'No family history of cancer',
        },
    ],
};

const orrFootnotes = [
    {
        label:'a.',
        text: 'Patients with complete response: n=12/50.'
    },
    {
        label:'b.',
        text: "Patients with partial response: n=16/50."
    },
]

const orr2Footnotes = [
    {
        label:'c.',
        text: 'Patients with complete response: n=9/55.'
    },
    {
        label:'d.',
        text: "Patients with partial response: n=18/55."
    },
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">Discover the Efficacy of KEYTRUDA for Your Appropriate Patients With Recurrent Locally Advanced or Metastatic Merkel Cell Carcinoma (MCC)</PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">

                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                                <EndpointDisplay 
                                    title="Objective Response Rate (ORR)"
                                    footnotes={orrFootnotes}
                                    definitions="CI = confidence interval."
                                    cards={
                                        [
                                            {
                                                dataType: 'ORR',
                                                label: 'KEYTRUDA',
                                                rate: '56%',
                                                ci: '95% CI, 41–70',
                                                n: '28/50',
                                                completeResponseRate: '24%<sup>a</sup>',
                                                partialResponseRate: '32%<sup>b</sup>',
                                            }
                                        ]
                                    }
                                />

                                <ComparisonTable 
                                        title="Duration of Response (DOR)" 
                                        definitions="NR = not reached."
                                        footnotes={dorComparisonFootnotes}
                                        data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA',
                                                },

                                            ],
                                            [
                                                {
                                                    label: 'Median DOR in months (range)',
                                                    dataPoint: 'NR',
                                                    dataSubPoint: '(5.9, 34.5+)'

                                                }
                                            ],
                                            [                                             
                                                {
                                                    label: 'Patients with responses lasting ≥6 months',
                                                    dataPoint: '96% ',
                                                    dataSubPoint: '(n=27/28)'
                                                }
                                            ],
                                            [
                                                {
                                                    label: 'Patients with responses lasting ≥12 months',
                                                    dataPoint: '54%',
                                                    dataSubPoint: '(n=15/28)',
                                                }
                                            ]
                                        ]
                                    } 
                                /> 
                        </PageSection>

                        <PageSection title={anchorLinkModelData[1].label}>
                            <EndpointDisplay 
                                    title="ORR"
                                    footnotes={orr2Footnotes}
                                    cards={
                                        [
                                            {
                                                dataType: 'ORR',
                                                label: 'KEYTRUDA',
                                                rate: '49%',
                                                ci: '95% CI, 35–63',
                                                n: '27/55',
                                                completeResponseRate: '16%<sup>c</sup>',
                                                partialResponseRate: '33%<sup>d</sup>',
                                            }
                                        ]
                                    }
                                />
                                <ComparisonTable 
                                        title="DOR" 
                                        footnotes={dorComparisonFootnotes}
                                        data={
                                            [
                                                [
                                                    {
                                                        legendTitle: 'KEYTRUDA',
                                                    },
    
                                                ],
                                                [
                                                    {
                                                        label: 'Median DOR in months (range)',
                                                        dataPoint: 'NR',
                                                        dataSubPoint: '(4.8, 25.4+)'
    
                                                    }
                                                ],
                                                [                                             
                                                    {
                                                        label: 'Patients with responses lasting ≥6 months',
                                                        dataPoint: '93% ',
                                                        dataSubPoint: '(n=25/27)'
                                                    }
                                                ],
                                                [
                                                    {
                                                        label: 'Patients with responses lasting ≥12 months',
                                                        dataPoint: '70%',
                                                        dataSubPoint: '(n=19/27)',
                                                    }
                                                ]
                                            ]
                                    } 
                                /> 
                        </PageSection>

                        <PageSection bgColor="cloud" title={anchorLinkModelData[2].label}>
                            <MCC />
                        </PageSection>

                        <PageSection title={anchorLinkModelData[3].label}>
                            <NCCNcallout 
                                references={NCCNcalloutReferences} 
                                data={NCCNcalloutData} 
                                definitions={NCCNcalloutDefinitions}
                            />
                        </PageSection>

                        <PageSection bgColor="cloud" title={anchorLinkModelData[4].label}>
                            <PageSubSection title="Based on Those Studied in KEYNOTE&#8288;-&#8288;017 and KEYNOTE&#8288;-&#8288;913">
                                <PatientProfile {...patientProfileNathan}
                                    definitions="LN = lymph node, PET/CT = positron emission tomography-computed tomography scan."
                                />
                            </PageSubSection>
                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
